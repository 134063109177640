<template>
    <div>
        <page-header
            :title="$t('pages.ReportLostOrStolen.ReportLostOrStolen')"
            :show-back="true"
            @onClose="returnToRoot"
        />
        <loading-indicator
            v-if="isLoading"
            :title="$t('global.loadingMessage.loading')"
        />
        <error-view
            v-else-if="errorText"
            :description="errorText"
        />
        <div
            v-else
            class="container"
        >
            <p class="fw-bold mb-1">
                {{ $t('pages.ReportLostOrStolen.Step1Title') }}
            </p>
            <p class="text-muted">
                {{ $t('pages.ReportLostOrStolen.Step1Subtitle') }}
            </p>
            <list-row-toggle
                :title="$t('pages.ReportLostOrStolen.CardActive')"
                :submitting="submitting"
                :disabled="toggleDisabled"
                v-model="isCardUnlocked"
                @change="toggleChanged"
                contained
            />
            <p class="fw-bold mt-3 mb-1">
                {{ $t('pages.ReportLostOrStolen.Step2Title') }}
            </p>
            <p class="text-muted mb-2">
                {{ $t('pages.ReportLostOrStolen.Step2Subtitle') }}
            </p>
            <p
                class="text-muted mb-2"
                v-html="formattedAddress"
            />
            <div class="mb-2">
                <div class="custom-control custom-radio d-inline-flex custom-control-tight">
                    <input
                        type="radio"
                        :id="CardReplacementReason.LOST"
                        :name="CardReplacementReason.LOST"
                        class="custom-control-input"
                        :value="CardReplacementReason.LOST"
                        v-model="replacementReason"
                    >
                    <label
                        class="custom-control-label custom-control-label-tight"
                        :for="CardReplacementReason.LOST"
                    >{{ $t('pages.ReplaceCard.lost.title') }}</label>
                </div>

                <div class="custom-control custom-radio d-inline-flex custom-control-tight">
                    <input
                        type="radio"
                        :id="CardReplacementReason.DAMAGED"
                        :name="CardReplacementReason.DAMAGED"
                        class="custom-control-input"
                        :value="CardReplacementReason.DAMAGED"
                        v-model="replacementReason"
                    >
                    <label
                        class="custom-control-label custom-control-label-tight"
                        :for="CardReplacementReason.DAMAGED"
                    >{{ $t('pages.ReplaceCard.damaged.title') }}</label>
                </div>
            </div>
            <base-button
                button-classes="btn btn-secondary"
                @click="goToReplaceCard"
            >
                {{ $t('global.cta.continue') }}
            </base-button>
        </div>
        <modal
            :show="modalVisible"
            :title="$t('pages.ReportLostOrStolen.CardLocked')"
            @close="modalVisible = false"
            disable-close
        >
            <p class="mb-2">
                {{ $t('pages.ReportLostOrStolen.ModalTitle') }}
            </p>
            <p class="fw-bold">
                {{ $t('pages.ReportLostOrStolen.CardStop') }}
            </p>
            <ul class="list-unstyled">
                <li>{{ $t('pages.ReportLostOrStolen.CardStopItem1') }}</li>
                <li>{{ $t('pages.ReportLostOrStolen.CardStopItem2') }}</li>
                <li>{{ $t('pages.ReportLostOrStolen.CardStopItem3') }}</li>
                <li>{{ $t('pages.ReportLostOrStolen.CardStopItem4') }}</li>
            </ul>
            <p class="fw-bold">
                {{ $t('pages.ReportLostOrStolen.CardContinue') }}
            </p>
            <ul class="list-unstyled">
                <li>{{ $t('pages.ReportLostOrStolen.CardContinueItem1') }}</li>
                <li>{{ $t('pages.ReportLostOrStolen.CardContinueItem2') }}</li>
                <li>{{ $t('pages.ReportLostOrStolen.CardContinueItem3') }}</li>
                <li>{{ $t('pages.ReportLostOrStolen.CardContinueItem4') }}</li>
            </ul>
            <base-button @click="modalVisible = false">
                {{ $t('pages.ReportLostOrStolen.GotIt') }}
            </base-button>
        </modal>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import BaseButton from '@/components/base/BaseButton'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { logger } from '@/utils/logger'
    import { cardHolderDetail, freezeCard, getCardEmbossingStatus } from '@/services/api'
    import { CoreCardEmbossingManualStatus, CardReplacementReason } from '@/data/constants'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import ListRowToggle from '@/components/ListRowToggle'
    import Modal from '@/components/Modal'
    import ErrorView from '@/components/ErrorView'
    import { i18n } from '@/utils/i18n'
    import { navigation } from '@/mixins/navigation'
    import { RoutePaths } from '@/routes/router'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { mapGetters } from 'vuex'

    export default {
        name: 'ReportLostOrStolen',
        components: {
            ErrorView,
            Modal,
            ListRowToggle,
            'base-button': BaseButton,
            PageHeader,
            'loading-indicator': LoadingIndicator,
        },
        mixins: [navigation],
        data: function () {
            return {
                submitting: true,
                isCardUnlocked: true,
                modalVisible: false,
                replacementReason: CardReplacementReason.LOST,
                errorText: '',
                CardReplacementReason,
                isLoading: false,
                addressGroup: null,
            }
        },
        mounted: function () {
            this.$logEvent('view_report_lost_card')
            this.getAddress()
            this.getCardStatus()
        },
        computed: {
            ...mapGetters(['isCryptoProduct', 'isInMarginCall']),
            formattedAddress() {
                if (!this.addressGroup) {
                    return ''
                }
                const unit = this.addressGroup.AddressLine2 ? `#${this.addressGroup.AddressLine2}` : ''
                return `${this.addressGroup.AddressLine1} ${unit}<br />${this.addressGroup.City}, ${this.addressGroup.State}, ${this.addressGroup.PostalCode}`
            },
            toggleDisabled() {
                return this.isCryptoProduct && this.isInMarginCall
            },
        },
        methods: {
            toggleChanged: function (updatedValue) {
                logger.info(`Unlock card toggle switched to: ${updatedValue}`)
                if (updatedValue) {
                    this.$logEvent('toggle_unfreeze_card')
                    this.$router.push({
                        name: 'UnfreezeCard',
                    })
                } else {
                    this.$logEvent('toggle_freeze_card')
                    this.freezeCard()
                }
            },
            getCardStatus: async function () {
                try {
                    logger.info('get card embossing status')
                    this.submitting = true
                    const response = await getCardEmbossingStatus()
                    logger.info(`received card embossing status: ${JSON.stringify(response?.data)}`)
                    if (response?.data?.success) {
                        this.isCardUnlocked = response.data.payload.embossingManualStatus === CoreCardEmbossingManualStatus.Active
                    }
                } catch (e) {
                    ApiErrorHandler(e)
                    this.errorText = i18n.t('pages.ReportLostOrStolen.CardStatusError')
                } finally {
                    this.submitting = false
                }
            },
            async getAddress() {
                this.isLoading = true
                try {
                    const response = await cardHolderDetail()
                    logger.info(`Receiving card holder details: ${JSON.stringify(response.data)}`)
                    if (!response.data?.success) {
                        logger.error(`Failed to get card holder details: ${response.data.error}`)
                        this.errorText = i18n.t('global.errors.generic')
                        return
                    }
                    this.addressGroup = response.data.payload.AddressGroup
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                } finally {
                    this.isLoading = false
                }
            },
            freezeCard: async function () {
                try {
                    this.submitting = true
                    logger.info(`ReportLostOrStolen freeze Card`)
                    const response = await freezeCard()
                    logger.info(`freeze Card response: ${JSON.stringify(response.data)}`)
                    if (response?.data?.success) {
                        this.modalVisible = true
                    } else {
                        this.errorText = i18n.t('pages.ReportLostOrStolen.FreezeCardError')
                    }
                } catch (e) {
                    ApiErrorHandler(e)
                    this.errorText = i18n.t('pages.ReportLostOrStolen.FreezeCardError')
                } finally {
                    this.submitting = false
                }
            },
            sendLostReportText() {
                location.href = `sms:${contact_info.sms}`
            },
            goToReplaceCard: function () {
                window.logEvent('click_button_continue_to_replace_card', { reason: this.replacementReason })
                this.$router.push({ path: RoutePaths.REPLACE_CARD, query: { reason: this.replacementReason } })
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/components/appBase';

    .replacement-reasons {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
